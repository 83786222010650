<template>
  <div>
    <v-row class="px-6">
      <v-col cols="10">
        <v-text-field
            v-if="upYoutube"
            prepend-icon="mdi-youtube"
            label="Enlace de YouTube"
            v-model="videoToAdd"
        ></v-text-field>
        <v-file-input
            v-else
            prepend-icon="mdi-file-video-outline"
            :loading="uploading"
            label="Subir a Carrusel de Actores"
            accept="video/mp4"
            v-model="videoToAdd"
        ></v-file-input>
      </v-col>
      <v-col>
        <v-tooltip
            bottom
        >
          <template v-slot:activator="{ on, attrs }">
            <div
                v-bind="attrs"
                v-on="on"
            >
              <v-switch
                  class="ml-4"
                  color="grey darken-3"
                  v-model="upYoutube"
              ></v-switch>
            </div>
          </template>
          <span>{{ upYoutube?'Usar Youtube':'Subir a CDA' }}</span>
        </v-tooltip>
      </v-col>
      <v-col
          class="d-flex justify-center align-center"
      >
        <v-btn
            icon
            outlined
            @click=newVideo()
        >
          <v-icon>
            {{ upYoutube?'mdi-content-save':'mdi-upload'}}
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="pa-2">
      <v-col
          v-for="vid in vids"
          class="d-flex justify-center"
          :key="vid.video.id"
      >
        <div v-if="!vid.isSelfhosted">
          <youtube
              :video-id=vid.video.id
              player-width="320"
              player-height="210"
          />
          <v-btn
              block
              elevation="0"
              small
              color="deep-orange lighten-1"
              class="mt-n1"
              @click=deleteVideo(vid)
          >
            <v-icon>
              mdi-trash-can-outline
            </v-icon>
            Eliminar
          </v-btn>
        </div>
        <div v-else>
          <video
              width="320"
              height="240"
              controls
              class="mt-n8"
          >
            <source :src="getCdaVideo(vid)" type="video/mp4">
            Tu navegador no soporta este video
          </video>
          <v-btn
              block
              elevation="0"
              small
              color="deep-orange lighten-1"
              class="mt-n1"
              @click=deleteVideo(vid)
          >
            <v-icon>
              mdi-trash-can-outline
            </v-icon>
            Eliminar
          </v-btn>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {getIdFromURL} from "vue-youtube-embed";
import {EventBus} from "@/main";

export default {
  name: "VideoUploadEdition",
  data: () => ({
    videoToAdd: null,
    uploading: false,
    upYoutube: true,
    vids: [],
  }),
  created() {
    this.retrieveVideos();
  },
  methods: {
    newVideo() {
      this.uploading = true;

      const requestUrl = () => {
        if (this.upYoutube) {
          const ytId = getIdFromURL(this.videoToAdd);

          if (!ytId || !this.videoToAdd.toLowerCase().includes('youtube')) {
            EventBus.$emit('alert',{
              color: 'red',
              type: 'error',
              msg: 'Video de YouTube no válido'
            });
            return null;
          }

          return `/protected/actor/${this.actorId}/upload?action=vid&vidId=${getIdFromURL(this.videoToAdd)}`;
        } else {
          return `/protected/actor/${this.actorId}/upload?action=vid`;
        }
      }

      const requestPayload = () => {
        if (this.upYoutube) {
          return {};
        } else {
          const fileToUpload = new FormData();
          fileToUpload.append('file',this.videoToAdd);
          return fileToUpload;
        }
      }

      const url = requestUrl();
      const payload = requestPayload();

      if (this.videoToAdd && url && payload) {
        this.axios
            .post( url, payload )
            .then( () => {
              EventBus.$emit('alert',{
                color: 'green',
                type: 'success',
                msg: 'Video añadido correctamente'
              });
            })
            .catch( e => {
              console.log(e);
              EventBus.$emit('alert',{
                color: 'red',
                type: 'error',
                msg: 'Ha ocurrido un error al añadir el video'
              });
            })
            .finally( () => {
              this.videoToAdd = null;
              this.uploading = false;
              this.retrieveVideos();
            });
      }

    },
    getCdaVideo(vid){
      return `${this.axios.defaults.baseURL}video/${vid.video.file}`;
    },
    deleteVideo(vid) {
      this.axios
          .post(`/protected/actor/${this.actorId}/upload?action=vid&vidId=${vid.video.id}&deleteVid=true`)
          .then( () => {
            EventBus.$emit('alert',{
              color: 'green',
              type: 'success',
              msg: 'Video eliminado correctamente'
            });
          })
          .catch( e => {
            console.log(e);
            EventBus.$emit('alert',{
              color: 'red',
              type: 'error',
              msg: 'Ha ocurrido un error al eliminar el video'
            });
          })
          .finally( () => {
            this.videoToAdd = null;
            this.uploading = false;
            this.retrieveVideos();
          });
    },
    retrieveVideos(){
      this.axios
          .get(`/actor?id=${this.actorId}`)
          .then( res => {
            this.vids = Object.values(res.data.sections.data.videos);
          })
          .catch( e => {
            console.log(e)
            EventBus.$emit('alert',{
              color: 'red',
              type: 'error',
              msg: e.response.data.message
            });
          });
    },
  },
  props: {
    actorId: {
      type: String,
      required: true
    }
  },
  watch: {
    actorId: {
      handler: function () {
        this.retrieveVideos();
      }
    }
  },
}
</script>

<style scoped>

</style>