<template>
  <v-row>
    <v-col>
      <v-list
          subheader
      >
        <v-subheader class="mt-4">
          <v-icon class="mr-4">mdi-notebook-outline</v-icon>
          INFORMACIÓN PRINCIPAL <span class="caption ml-4">(Los campos que no se rellenen, no saldrán en la ficha)</span>
        </v-subheader>
      </v-list>
      <v-divider></v-divider>
      <v-list>
        <v-list-item
            v-for="(value, field) in profile"
            :key=field
            v-if="profile != null && field !== 'id'"
        >
          <v-text-field
              v-if="getFieldData(field, 'type') === 'text'"
              :prepend-icon="getFieldData(field, 'icon')"
              :label="getFieldData(field, 'label')"
              v-model="profile[field]"
          ></v-text-field>
          <v-combobox
              v-if="getFieldData(field, 'type') === 'combobox' && getFieldData(field, 'isObject')"
              item-text="name"
              :prepend-icon="getFieldData(field, 'icon')"
              :label="getFieldData(field, 'label')"
              v-model="profile[field]"
              multiple
              chips
              @change="updateCombobox(field)"
          ></v-combobox>
          <v-combobox
              v-if="getFieldData(field, 'type') === 'combobox' && !getFieldData(field, 'isObject')"
              :prepend-icon="getFieldData(field, 'icon')"
              :label="getFieldData(field, 'label')"
              v-model="profile[field]"
              multiple
              chips
          ></v-combobox>
          <v-select
              v-if="getFieldData(field, 'type') === 'select'"
              :prepend-icon="getFieldData(field, 'icon')"
              :label="getFieldData(field, 'label')"
              v-model="profile[field]"
              :items="getFieldData(field, 'items')"
              :chips="false"
              :multiple="false"
          ></v-select>
        </v-list-item>
      </v-list>
      <template v-if="profile.languages.length > 0">
        <v-list>
          <v-subheader>
            <v-icon class="mr-4">mdi-translate-variant</v-icon>
            Configurar Idiomas
          </v-subheader>
        </v-list>
        <v-divider></v-divider>
        <v-list>
          <v-list-item v-for="lang in profile.languages">
            <v-text-field
                :label="lang.name"
                hide-details="auto"
                v-model="lang.percent"
                @change="updateLangToNearest10(lang)"
            ></v-text-field>
          </v-list-item>
        </v-list>
      </template>
    </v-col>
  </v-row>
</template>

<script>
import { EventBus } from "@/main";
import PersonalData from '@/constants/PersonalData';
import {mixins} from "@/mixins/mixins";

export default {
  name: "ActorEditProfile",
  mixins: [mixins],
  props: {
    actorId: {
      type: Number,
      required: true
    },
  },
  watch: {
    actorId: {
      handler: function () {
        this.retrieveProfileInfo();
      }
    }
  },
  created() {
    this.retrieveProfileInfo();
    EventBus.$on('actorEditionForm_actorEditProfile_save', this.save);
  },
  methods: {
    updateLangToNearest10(lang) {
      if (lang.percent >= 0 && lang.percent <= 100)
        lang.percent = Math.round(lang.percent / 10) * 10;
      else
        lang.percent = 0;
    },
    updateCombobox(field) {
      if (field === 'languages') {
        const updatedLangs = [];
        this.profile[field].forEach(item => {
          if (this.isObject(item))
            updatedLangs.push(item)
          else
            updatedLangs.push({name: item, percent: 0})
        });
        this.profile[field] = updatedLangs;
      }
    },
    getFieldData(field, sub) {
      return PersonalData[field][sub];
    },
    getPercent(percent) {
      return percent
          ? Math.ceil(percent).toFixed(0)
          : 0;
    },
    save() {
      if (this.profile.name.length < 1) {
        EventBus.$emit('alert', {
          color: 'red',
          type: 'error',
          msg: 'El perfil no puede estar vacío'
        });
      } else {
        this.axios
            .post(`/protected/actor/${this.profile.id}/update/profile`, this.profile)
            .then(res => {
              if (res.status === 200) {
                EventBus.$emit('alert', {
                  color: 'green',
                  type: 'success',
                  msg: `Se ha actualizado correctamente el perfil de ${this.profile.name}`
                });
              }
              EventBus.$emit('toActorGallery_reloadGallery');
              EventBus.$emit('toAdminPanel_updateActorCloseDialogs', this.profile.name);
            })
            .catch(e => {
              EventBus.$emit('alert', {
                color: 'red',
                type: 'error',
                msg: `Ha ocurrido un error actualizando el perfil de ${this.profile.name}`
              });
            });
      }
    },
    retrieveProfileInfo() {
      this.axios
          .get(`/protected/actor/${this.actorId}/data/profile`)
          .then(res => {
            this.profile = res.data;
          })
          .catch(e => {
            EventBus.$emit('alert', {
              color: 'red',
              type: 'error',
              msg: 'Ha ocurrido un error obteniendo los datos del perfil'
            });
          });
    }
  },
  data: () => ({
    profile: {
      id: null,
      name: "",
      age: 0,
      hair: "",
      eyes: "",
      height: "",
      languages: [],
      skills: [],
      sex: ""
    }
  })
}
</script>

<style scoped>

</style>