<template>
  <div>
    <v-row class="px-6">
      <v-col cols="10">
        <v-file-input
            label="Archivo"
            :loading="uploading"
            accept="image/png, image/jpeg, image/bmp"
            v-model=file
        ></v-file-input>
      </v-col>
      <v-col
          class="d-flex justify-center align-center"
      >
        <v-btn
            icon
            outlined
            @click=addFile()
        >
          <v-icon>
            mdi-upload
          </v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="px-6">
      <v-col
          v-for="img in images"
          class="d-flex justify-center"
          :key=img.id
      >
        <v-card
            outlined
            class="pa-1"
            width="200px"
        >
          <v-img
              :src=addFaceDetection(img.url)
              aspect-ratio="1"
              class="grey lighten-2 actorImg align-end"
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    color="grey lighten-5"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-card-actions
                class="d-flex justify-center align-center"
            >
              <v-btn
                  x-small
                  tile
                  color="teal lighten-3"
                  :disabled=img.main
                  @click="updateImg(img.id, 'main')"
              >
                <v-icon>
                  mdi-check
                </v-icon>
              </v-btn>
              <v-btn
                  x-small
                  tile
                  color="deep-orange lighten-3"
                  :disabled=img.main
                  @click="updateImg(img.id, 'delete')"
              >
                <v-icon>
                  mdi-close
                </v-icon>
              </v-btn>
            </v-card-actions>
          </v-img>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {EventBus} from "@/main";
import {mixins} from "../../mixins/mixins";
export default {
  name: "PhotoUploadEdition",
  mixins: [mixins],
  props: {
    actorId: {
      required: true
    },
  },
  watch: {
    actorId: {
      handler: function () {
        this.retrieveImages();
      }
    }
  },
  data: () => ({
    images: [],
    file: null,
    uploading: false,
  }),
  created() {
    this.retrieveImages();
  },
  methods: {
    addFile(){
      if (this.file == null) {
        EventBus.$emit('alert',{
          color: 'red',
          type: 'error',
          msg: 'Debes seleccionar un archivo'
        });
        return;
      }

      this.uploading = true;
      const fileToUpload = new FormData();
      fileToUpload.append('file', this.file);
      fileToUpload;
      this.axios
          .post(`/protected/actor/${this.actorId}/upload?action=img`, fileToUpload)
          .then( res => {
            EventBus.$emit('alert',{
              color: 'green',
              type: 'success',
              msg: res.data.message
            });
            EventBus.$emit('toActorGallery_reloadGallery');
          })
          .catch( e => {
            console.log(e);
            EventBus.$emit('alert',{
              color: 'red',
              type: 'error',
              msg: 'Ha ocurrido un error al subir el archivo'
            });
          })
          .finally( () => {
            this.uploading = false;
            this.retrieveImages();
          });
    },
    retrieveImages(){
      this.axios
          .get(`/actor?id=${this.actorId}`)
          .then( res => {
            this.images = Object.values(res.data.images);
          })
          .catch( e => {
            console.log(e)
            EventBus.$emit('alert',{
              color: 'red',
              type: 'error',
              msg: e.response.data.message
            });
          });
    },
    updateImg(publicId, action){
      this.axios
          .post(`/protected/actor/${this.actorId}/update/multimedia?action=${action}`, { publicId })
          .then( () => {
            this.retrieveImages();
            EventBus.$emit('toActorGallery_reloadGallery');
          })
          .catch( e => {
            EventBus.$emit('alert',{
              color: 'red',
              type: 'error',
              msg: 'Ha ocurrido un error cambiando el estado de la imagen'
            });
          });
    },
  }
}
</script>

<style scoped>

</style>