const CDA_TOKEN = 'cda-token';
export const mixins = {
    methods: {
        saveToken(token){
            localStorage.setItem(CDA_TOKEN, token);
        },
        removeSession(){
            localStorage.removeItem(CDA_TOKEN);
        },
        isSessionSetted() {
            return (localStorage.getItem(CDA_TOKEN) != null)
        },
        getToken(){
            return localStorage.getItem(CDA_TOKEN);
        },
        addFaceDetection(url) {
            if (url) return url.replace('image/upload/', 'image/upload/c_fill,c_thumb,g_face,w_650,h_650/');
        },
        getWindowWidth() {
            return window.innerWidth;
        },
        isObject(value) {
            return typeof value === 'object' && value !== null && !Array.isArray(value);
        }
    },
}