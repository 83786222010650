<template>
  <v-app-bar elevation="0">
    <v-btn
        small
        tile
        outlined
        class="ml-2"
        @click=visitSite()
    >
      Visitar Página
    </v-btn>
    <v-spacer></v-spacer>
      <v-col
          cols=4
          class="d-flex justify-center align-center"
      >
        <v-btn
            tile
            outlined
            v-if=!newActorInput
            @click="newActorInput = true"
        >
          Añadir Actor
        </v-btn>
        <v-btn
            class="ml-4"
            tile
            outlined
            v-if=!newActorInput
            @click="showRedesDialog"
        >
          Modificar Redes
        </v-btn>
        <div
            v-if=newActorInput
            class="d-flex justify-center align-center"
        >
          <v-btn
              icon
              small
              tile
              outlined
              class="mr-2"
              @click="newActorInput = false"
          >
            <v-icon>
              mdi-close
            </v-icon>
          </v-btn>
          <v-text-field
              label="Nombre"
              hide-details="auto"
              v-model=actorName
          ></v-text-field>
          <v-btn
              icon
              small
              tile
              outlined
              class="ml-2"
              @click=createNewActor
          >
            <v-icon>
              mdi-check
            </v-icon>
          </v-btn>
        </div>
      </v-col>
    <v-spacer></v-spacer>
    <v-btn
        small
        tile
        outlined
        class="ml-2"
        @click="logout()"
    >
      Cerrar Sesión
    </v-btn>
  </v-app-bar>
</template>

<script>
import {EventBus} from "@/main";
import {mixins} from "@/mixins/mixins";

export default {
  name: "AdminBar",
  mixins: [mixins],
  data: () => ({
    newActorInput: false,
    actorName: ''
  }),
  methods: {
    createNewActor(){
      this.axios
          .post(`/protected/actor/new`,{ name: this.actorName })
          .then( res => {
            if (res.status >= 200){
              EventBus.$emit('alert',{
                color: 'green',
                type: 'success',
                msg: `Creado nuevo perfil`
              });
              EventBus.$emit('toActorGallery_reloadGallery');
            }
          })
          .catch( () => {
            EventBus.$emit('alert',{
              color: 'red',
              type: 'error',
              msg: `Error creando perfil`
            })
          });
      this.actorName = '';
      this.newActorInput = false;
    },
    showRedesDialog() {
      EventBus.$emit('adminActions_showRedesDialog');
    },
    visitSite() {
      window.open('https://carruseldeactores.com', '_blank');
    },
    logout() {
      this.removeSession();
      location.reload(true);
    }
  }
}
</script>

<style scoped>

</style>