<template>
  <v-row>
    <v-col>
      <v-row>
        <v-col
            v-for="category in availableCategories"
            :key="category.key"
            class="d-flex"
        >
          <v-btn
              block
              tile
              text
              class="pa-6"
              @click="changeActiveTab(category)"
          >
            <v-switch
                v-if="isActiveCategory(category)"
                v-model="bgs[activeTab.key].isActive"
                @change="updateBackground(
                    {
                      bool: bgs[activeTab.key].isActive,
                      groupId: activeBg.id
                    },
                    'switch'
                )"
                color="grey"
                dense
            />
            <span :class="isActiveCategory(category) ? 'font-weight-medium' : 'font-weight-light'">
              {{ category.bgName }}
            </span>
          </v-btn>
        </v-col>
      </v-row>
      <v-divider></v-divider>
      <v-list>
        <v-row class="pr-12 pl-9 pt-3">
          <v-combobox
              prepend-icon="mdi-shape-outline"
              label="Añadir categoría"
              multiple
              chips
              v-model="activeBg.categories"
              @change="updateSubcategories()"
          ></v-combobox>
        </v-row>
      </v-list>
      <v-list
          subheader
      >
        <v-row class="px-6">
          <v-col cols="3">
            <v-select
                prepend-icon="mdi-shape-outline"
                label="Categoría"
                v-model=newBg.category
                :items="activeBg.categories"
                :disabled="loadingAdd"
            ></v-select>
          </v-col>
          <v-col cols="3">
            <v-text-field
                prepend-icon="mdi-calendar-range"
                label="Fecha"
                v-model=newBg.date
                :disabled="loadingAdd"
            ></v-text-field>
          </v-col>
          <v-col cols="5">
            <v-text-field
                prepend-icon="mdi-information-outline"
                label="Descripción"
                v-model=newBg.exp
                :disabled="loadingAdd"
            ></v-text-field>
          </v-col>
          <v-col
            class="d-flex justify-center align-center"
          >
            <v-btn
                small
                icon
                outlined
                @click=addBg()
                :disabled="loadingAdd"
            >
              <v-icon>
                mdi-plus
              </v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-list>
      <v-list v-if="activeBg && activeBg.categories">
        <template v-for="subCat in activeBg.categories">
          <v-subheader>{{ subCat }}</v-subheader>
        <v-divider></v-divider>
          <template v-for="bg in getBgsByCategory(subCat)">
            <v-list-item :key="`bg_${bg.id}`">
              <v-list-item-icon>
                <v-icon class="ml-4 mt-1">mdi-pin</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ bg.exp }}</v-list-item-title>
                <v-list-item-subtitle>{{ bg.date }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-action>
                <v-btn
                    small
                    icon
                    outlined
                    class="mt-2"
                    @click="removeBg(bg.id)"
                >
                  <v-icon>
                    mdi-close
                  </v-icon>
                </v-btn>
              </v-list-item-action>
            </v-list-item>
            </template>
        </template>
      </v-list>
    </v-col>
  </v-row>
</template>

<script>
import {EventBus} from "@/main";

export default {
  name: "ActorEditBackground",
  data: () => ({
    loadingAdd: false,
    activeTab: null,
    activeBg: {
      categories: []
    },
    bgs: {},
    beforeUpdateCategories: [],
    availableCategories: [],
    newBg: {
      date: null,
      exp: null,
      category: null
    },
  }),
  props: {
    actorId: {
      required: true
    },
  },
  watch: {
    actorId(newVal, oldVal) {
      if (typeof newVal === "number" && newVal !== oldVal) {
        this.retrieveBackgroundInfo(newVal);
      }
    },
  },
  methods:{
    addBg(){
      if (this.checkAllInputs()) {
        this.updateBackground(
            {
              data: this.newBg,
              groupId: this.activeBg.id
            },
            'add'
        )
        setTimeout( () => {
          this.retrieveBackgroundInfo(this.actorId)
        },220);
      } else {
        EventBus.$emit('alert',{
          color: 'red',
          type: 'error',
          msg: 'Los campos no pueden estar vacíos'
        });
      }
    },
    checkAllInputs(){
      return (this.newBg.category && this.newBg.exp && this.newBg.date);
    },
    changeActiveTab(category) {
      this.activeTab = category;
      this.activeBg = null;
      this.activeBg = this.bgs[category.key];
      this.newBg =  {
        date: null,
        exp: null,
        category: null
      };
      if (this.activeBg) this.beforeUpdateCategories = this.activeBg.categories;
    },
    retrieveBackgroundInfo(actorId) {
      if (actorId && actorId !== "") {
        this.axios
            .get(`/protected/actor/${actorId}/data/background`)
            .then(res => {
              this.bgs = res.data.actorBackgrounds;
              this.availableCategories = res.data.availableBgCategories;
              if (this.activeTab == null)
                this.changeActiveTab(this.availableCategories[0]);
              else
                this.changeActiveTab(this.activeTab);
            })
            .catch(e => {
              console.log("e",e)
              EventBus.$emit('alert', {
                color: 'red',
                type: 'error',
                msg: 'Ha ocurrido un error obteniendo los datos del perfil'
              });
            });
      }
    },
    isActiveCategory(category) {
      return category.key === this.activeTab.key
    },
    updateBackground(payload, bgAction) {
      this.axios
          .post(`/protected/actor/${this.actorId}/update/background?action=${bgAction}`, payload)
          .catch(e => {
            console.log("e",e)
            EventBus.$emit('alert', {
              color: 'red',
              type: 'error',
              msg: 'Ha ocurrido un error actualizando los datos del perfil'
            });
          });
    },
    getBgsByCategory(category) {
      return this.activeBg.experiences.filter(e => e.category === category);
    },
    updateSubcategories() {
      const isAdd = this.activeBg.categories.length > this.beforeUpdateCategories.length;
      const category = isAdd
          ? this.activeBg.categories.find( c => !this.beforeUpdateCategories.includes(c))
          : this.beforeUpdateCategories.find( c => !this.activeBg.categories.includes(c));

      if (category) {
        const categoryBgs = this.getBgsByCategory(category);
        if (categoryBgs && categoryBgs.length > 0) {
          EventBus.$emit('alert', {
            color: 'red',
            type: 'error',
            msg: `La categoría ${category} aun tiene ${categoryBgs.length} registros asociados y no se puede eliminar`
          });
          this.activeBg.categories.push(category);
        } else {
          this.updateBackground(
              {
                bool: isAdd,
                data: category,
                groupId: this.activeBg.id
              },
              'subcategories'
          );
          this.beforeUpdateCategories = this.activeBg.categories;
        }
      }

    },
    removeBg(bgId) {
      this.updateBackground(
          {
            data: bgId,
            groupId: this.activeBg.id
          },
          'remove'
      );
      this.activeBg.experiences = this.activeBg.experiences.filter(e => e.id !== bgId);
    }
  },
  created() {
    this.retrieveBackgroundInfo(this.actorId);
    EventBus.$on('actorEditionForm_actorEditBackground_save',
        this.save
    );
  }
}
</script>

<style scoped>
.v-tab {
  border-left: 1px solid rgba(0, 0, 0, .1);
  border-right: 1px solid rgba(0, 0, 0, .1);
}
.v-tab--active {
  text-shadow: 0 0 1px rgba(0, 0, 0, .4);
}
</style>