export default {
    name: {
        label: "Nombre",
        icon: "mdi-form-textbox",
        type: "text",
        value: null
    },
    age: {
        label: "Edad",
        icon: "mdi-cake-variant-outline",
        type: "text",
        value: null
    },
    hair: {
        label: "Cabello",
        icon: "mdi-hair-dryer-outline",
        type: "text",
        value: null
    },
    eyes: {
        label: "Ojos",
        icon: "mdi-eye-outline",
        type: "text",
        value: null
    },
    height: {
        label: "Altura",
        icon: "mdi-human-male-height",
        type: "text",
        value: null
    },
    languages: {
        label: "Idiomas",
        icon: "mdi-account-voice",
        type: "combobox",
        value: [],
        isObject: true,
    },
    skills: {
        label: "Habilidades",
        icon: "mdi-drama-masks",
        type: "combobox",
        value: null,
        isObject: true
    },
    sex: {
        label: "Sexo",
        icon: "mdi-human",
        type: "select",
        chips: false,
        multiple: false,
        items: ['ACTOR', 'ACTRIZ'],
        value: null
    }
};