<template>
  <v-container>
    <v-row class="pa-8 mx-auto" style="max-width: 1200px">
      <template
          v-if="actors.length > 0"
          v-for="a in actors"
      >
        <v-col
            v-if="showCard(a)"
            :key="a.id"
            class="d-flex justify-center child-flex"
            cols="12"
            sm="6"
            md="4"
            lg="3"
        >
          <v-card
              outlined
              max-width="350px"
          >
            <v-img
                :key="`${a.id}_img`"
                :src=getMainImg(a)
                aspect-ratio="1"
                class="grey lighten-2 actorImg align-end"
            >
              <template v-slot:placeholder>
                <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                >
                  <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                  ></v-progress-circular>
                </v-row>
              </template>
              <div class="showName text-center">
                {{ a.name }}
              </div>
              <div v-if="admin">
                <ActorAdminActions
                    :actor-id=a.id
                    :actor-name=a.name
                    :actor-active=a.isActive
                    :can-activate=canActivate(a)
                >
                </ActorAdminActions>
              </div>
            </v-img>
          </v-card>
        </v-col>
      </template>
    </v-row>
  </v-container>
</template>

<script>
import ActorAdminActions from "@/components/ActorAdminActions.vue";
import {EventBus} from "@/main";
import {mixins} from "../mixins/mixins";
import ApiRoute from "@/constants/ApiRoute";

export default {
  name: "ActorGallery",
  mixins: [mixins],
  components: {ActorAdminActions},
  props: {
    admin: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    actors: []
  }),
  created() {
    this.getGallery();
    EventBus.$on('toActorGallery_reloadGallery', () => {
      this.getGallery();
    });
  },
  methods: {
    getGallery(){
      this.actors = [];
      this.axios
          .get(`/gallery?showInactive=${this.admin}`)
          .then( res => {
            this.actors = res.data;
          })
          .catch( e => console.log(e) );
    },
    showCard(actor){
      if (!this.admin){
        return actor.active;
      }
      return true;
    },
    getMainImg(actor) {
      return this.canActivate(actor) ? actor.mainImg : (ApiRoute.getUrl() + actor.mainImg);
    },
    canActivate(actor) {
      return actor.mainImg.startsWith('http');
    }
  }
}
</script>

<style scoped>
.actorImg {
  transition: opacity 0.4s ease-in-out;
}

.actorImg:hover {
  opacity: 0.95;
}
.showName{
  color: white;
  background-color: rgba(0, 0, 0, 0.6);
  height: 42px;
  transition: opacity 0.4s ease-in-out;
  display: flex;
  justify-content: center;
  align-items: center;
}
.actorImg:hover .showName {
  background-color: rgba(0, 0, 0, 0.3);
  opacity: 0.8;
}
</style>