<template>
  <v-row justify="center">
    <v-dialog
        transition="fade-transition"
        v-model="showDialog"
        persistent
        max-width="420px"
    >
      <v-card tile>
        <v-card-title>
          <span class="text-h5">Editar Redes CDA</span>
        </v-card-title>
        <v-card-text>
          <v-list>
            <v-list-item v-for="rs in rrss" :key="rs.name">
              <v-text-field
                  :prepend-icon=rs.icon
                  :label=rs.name
                  v-model=rs.value
              ></v-text-field>
            </v-list-item>
          </v-list>
        </v-card-text>
        <v-card-actions>
          <v-row class="flex justify-center">
            <v-btn
                tile
                text
                @click="closeDialog"
            >
              Cerrar
            </v-btn>
            <v-btn
                tile
                text
                @click="submitChangeRRSS"
            >
              Enviar
            </v-btn>
          </v-row>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>

import {EventBus} from "@/main";

export default {
  name: "CdaRedesDialog",
  props: {
    showDialog: {
      required: true
    }
  },
  data: () => ({
    rrss: {
      twitter: { name: 'Twitter', icon: 'mdi-twitter', value: null },
      facebook: { name: 'Facebook', icon: 'mdi-facebook', value: null },
      youtube: { name: 'Youtube', icon: 'mdi-youtube', value: null },
      email: { name: 'Correo', icon: 'mdi-email-outline', value: null }
    }
  }),
  methods: {
    closeDialog(){
      EventBus.$emit('adminActions_showRedesDialog');
    },
    submitChangeRRSS(){
      Object.keys(this.rrss).forEach( key => {
        const value = this.rrss[key].value;
        if (value) {
          this.axios
              .post(`/protected/cda/cfg`, { key: key, val: value })
              .then( () => {
                EventBus.$emit('alert',{
                  color: 'green',
                  type: 'success',
                  msg: 'Redes actualizadas'
                });
                this.getRRSS();
              })
              .catch(e => {
                console.log(e)
                EventBus.$emit('alert', {
                  color: 'red',
                  type: 'error',
                  msg: `Ha ocurrido un error obteniendo la RRSS ${key} de CDA`
                });
              });
        }
      })
    },
    getRRSS(){
      Object.keys(this.rrss).forEach( key => {
        this.axios
            .get(`/cda?k=${key}`)
            .then(res => this.rrss[key].value = res.data.val)
            .catch(() => null);
      })
    }
  },
  created() {
    this.getRRSS();
  }
}
</script>

<style scoped>

</style>