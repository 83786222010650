import Vue from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from './plugins/vuetify'
import axios from 'axios'
import vueYoutubeEmbed from "vue-youtube-embed";
import VueAxios from 'vue-axios'
import ApiRoute from "@/constants/ApiRoute";

Vue.use(VueAxios,axios);
Vue.use(vueYoutubeEmbed);

Vue.config.productionTip = false

axios.defaults.baseURL = ApiRoute.getUrl();

const CDA_TOKEN = 'cda-token';
axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem(CDA_TOKEN);
        if (token && config.url && config.url.includes('protected')) {
            config.headers['x-cda-auth'] = token;
        }
        config.headers['Accept'] = 'application/json';
        return config;
    },
    error => Promise.reject(error)
);
export const EventBus = new Vue();

new Vue({
    router,
    vuetify,
    render: h => h(App)
}).$mount('#app')
