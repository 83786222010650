import Vue from 'vue'
import VueRouter from 'vue-router'
import AdminLogin from "@/views/AdminLogin.vue";
import AdminPanel from "@/views/AdminPanel.vue";

const CDA_TITLE = "Carrusel de Actores"
const CDA_TOKEN = 'cda-token';

Vue.use(VueRouter)

const routes = [
  {
    path: '/cda-admin',
    name: 'admin-panel',
    meta: {
      admin: true,
      title: "Panel de Admin"
    },
    component: AdminPanel
  },
  {
    path: '/',
    name: 'admin-login',
    component: AdminLogin,
    meta: {
      admin: false,
      title: "Login"
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  document.title = (CDA_TITLE + " - " + to.meta['title'] + " - " + new Date().getFullYear());
  const token = localStorage.getItem(CDA_TOKEN);
  if (to.meta.admin && !token) next("/");
  next();
});

const paramCleaner = (param) => {
  return param.includes("-")
      ? param.split('-')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase() )
          .join(' ')
      : param.charAt(0).toUpperCase() + param.slice(1).toLowerCase();
}

export default router;
